import React from "react";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainPink,
  darkGray,
  screen,
  innerWidth,
} from "../components/common/variables";

const Wrapper = styled.div`
  background: ${mainWhite};
  .privacy {
    padding-top: 123px;
    min-height: 100vh;
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 123px 30px 30px 30px;
    @media ${screen.xsmall} {
      padding: 150px 70px 100px 70px;
    }

    @media ${screen.large} {
      padding: 150px 0 100px 0;
    }

    &__page-title {
      color: ${darkGray};
      font-weight: 500;
      font-size: 1.25rem;
      max-width: ${innerWidth};
      margin: 0 auto 40px auto;
      @media ${screen.xsmall} {
        font-size: 1.68rem;
      }
      @media ${screen.medium} {
        font-size: 1.7rem;
      }
    }

    h4 {
      color: ${mainPink};
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 7px;
      @media ${screen.xsmall} {
        font-size: 1.15rem;
        margin-bottom: 15px;
      }
    }

    p {
      font-size: 1rem;
      margin-bottom: 25px;
      max-width: 800px;
      @media ${screen.xsmall} {
        font-size: 1.05rem;
        margin-bottom: 30px;
      }
      @media ${screen.medium} {
        font-size: 1.1rem;
      }
    }
  }
`;

const NotFoundPage = () => {
  const ogUrl = typeof window !== "undefined" && window.location.href;

  const currentYear = new Date().getFullYear();

  return (
    <>
      <SEO title="Disclaimer, Copyright and Privacy" url={ogUrl} />
      <Wrapper>
        <div className="privacy">
          <h2 className="privacy__page-title">
            DISCLAIMER, COPYRIGHT AND PRIVACY
          </h2>

          <h4>IMPORTANT</h4>
          <p>Your use of this site is subject to the terms set out below.</p>

          <h4>COPYRIGHT</h4>
          <p>
            This Website and its contents are the property of Peddl.in and are
            subject to copyright under Australian law and through international
            treaties, other countries. No use of the Website or contents is
            permitted without Peddl.in’s authorisation.
          </p>

          <h4>JURISDICTION</h4>
          <p>
            Use of this site and the provision of services by Peddl.in are
            governed by and subject to Australian law. It is a condition of your
            use of this site that you submit to this jurisdiction.
          </p>

          <h4>DISCLAIMER</h4>
          <p>
            The information contained within this Website and on any Peddl.in’s
            products and services is of a general nature only. Changes may occur
            in circumstances at any time which may affect the accuracy or
            completeness of the information so while Peddl.in have taken all
            reasonable care in producing this Website, it accepts no
            responsibility for any loss, expense or liability which you may
            incur from using or relying on the contents in this Website. Any
            legal rights and obligations which Peddl.in and you may have will be
            contained in the terms and conditions of contractual information
            issued to you.
          </p>

          <h4>LINKS WITHIN THIS WEBSITE</h4>
          <p>
            This site may contain links to other Internet sites that belong to
            third parties. Peddl.in accepts no responsibility for the accuracy
            of the content of those sites or your reliance on any information
            contained within any such site.
          </p>

          <h4>YOUR USE OF THIS WEBSITE</h4>
          <p>
            You may view, copy, print and distribute material in electronic or
            other forms only for your personal use or non-commercial purposes.
            If you do so, you must retain copyright and other intellectual
            property notices from the original material.
          </p>

          <h4>YOU MUST NOT</h4>
          <p>
            Use or distribute material from this site for any public or
            commercial purpose, or breach copyright or any other intellectual
            property rights of the presenters or its licensors including by
            amending or adapting any material on this site.
          </p>

          <h4>PRIVACY</h4>
          <p>
            Protecting your privacy and the confidentiality of your personal
            information is important to us, as it is fundamental to the way I
            conduct business. Peddl.in is sensitive to privacy issues and treats
            very seriously the ongoing trust our customers have placed in us.
            Peddl.in will not supply any personal information supplied via its
            website to any third party.
          </p>
          <p>Copyright Peddl.in {currentYear}</p>
        </div>
      </Wrapper>
    </>
  );
};

export default NotFoundPage;
